<template>
    <div class="flex items-center">
        <button class="focus:outline-none" :disabled="disable" @click="onPress">
            <span
                class="
                    w-10
                    h-6
                    my-1
                    flex
                    items-center
                    bg-gray-300
                    rounded-full
                    p-1
                    duration-300
                    ease-in-out
                "
                :class="{ 'bg-yellow-300': toggle }"
            >
                <span
                    class="
                        bg-white
                        w-4
                        h-4
                        rounded-full
                        shadow-md
                        transform
                        duration-300
                        ease-in-out
                    "
                    :class="{ 'translate-x-4': toggle }"
                ></span>
            </span>
        </button>
    </div>
</template>

<script>
export default {
    name: "Switch",
    props: { toggle: { required: true, type: Boolean }, disable: Boolean },
    emits: ["onPress"],
    methods: {
        onPress() {
            this.$emit("onPress")
        }
    }
}
</script>

<style scoped></style>
