<template>
    <Modal>
        <div class="flex justify-end">
            <button @click="onCancel">X</button>
        </div>
        <Calendar
            class="rounded-md -mx-4"
            :sel-date="date"
            :min-date="minDate"
            @onSelect="onSelect"
            @onChange="onChange"
    /></Modal>
</template>

<script>
import Calendar from "@/components/Calendar"
import Modal from "@/components/Modal"
export default {
    name: "CalendarModal",
    components: { Modal, Calendar },
    props: {
        selDate: { required: false, type: String },
        minDate: { required: false, type: String }
    },
    data() {
        return {
            date: this.selDate.replaceAll("-", "/")
        }
    },
    emits: ["onCancel", "onSelect", "onChange"],
    methods: {
        onCancel() {
            this.$emit("onCancel")
        },
        onSelect(val) {
            let date = `${val.year}/${
                val.month < 10 ? "0" + val.month : val.month
            }/${val.date < 10 ? "0" + val.date : val.date}`
            this.$emit("onSelect", date)
        },
        onChange(val) {
            let date = `${val.year}/${
                val.month < 10 ? "0" + val.month : val.month
            }/01`
            this.$emit("onChange", date)
        }
    }
}
</script>

<style scoped></style>
