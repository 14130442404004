<template>
    <div>
        <div class="flex justify-center">
            <div class="w-full max-w-7xl">
                <div class="flex flex-1 justify-end">
                    <button
                        class="
                            px-2
                            py-1
                            text-white
                            rounded-md
                            bg-gray-500
                            shadow-lg
                            hover:bg-gray-400
                            transition
                            duration-300
                            ease-in-out
                        "
                        @click="selectAllSpace"
                    >
                        {{
                            selectAll
                                ? $t("dashboard.unselect_all")
                                : $t("dashboard.select_all")
                        }}
                    </button>
                    <button
                        class="
                            ml-4
                            px-2
                            py-1
                            text-white
                            rounded-md
                            bg-gray-500
                            shadow-lg
                            hover:bg-gray-400
                            transition
                            duration-300
                            ease-in-out
                        "
                        @click="setZero(roomType)"
                    >
                        {{ $t("dashboard.set_zero") }}
                    </button>
                </div>
                <div class="overflow-x-scroll -mx-4">
                    <table class="table-auto min-w-full divide-gray-600">
                        <thead>
                            <tr>
                                <th
                                    class="
                                        pl-2
                                        sm:pl-4
                                        py-5
                                        text-left
                                        lg:text-2xl
                                        font-medium
                                        text-black
                                        tracking-wider
                                    "
                                >
                                    {{ $t("dashboard.room_type") }}
                                </th>
                                <th
                                    v-for="(type, index) in types"
                                    :key="index"
                                    class="
                                        sm:pl-4
                                        py-3
                                        text-center
                                        lg:text-lg
                                        font-medium
                                        text-black
                                        tracking-wider
                                    "
                                >
                                    <input
                                        v-model="type.isSelected"
                                        @change="checkSelection(roomType)"
                                        type="checkbox"
                                    />
                                    <button @click="selectCheckBox(type)">
                                        {{ type.book_type }}
                                        <br />
                                        {{ type.name }}
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            <tr
                                class="bg-white text-left font-medium text-sm"
                                :class="{
                                    'bg-gray-200': index % 2 === 0
                                }"
                                v-for="(room, name, index) in roomType"
                                :key="index"
                            >
                                <td class="pl-2 sm:pl-4 py-1">
                                    <input
                                        v-model="room.isSelected"
                                        @change="checkSelection(roomType)"
                                        type="checkbox"
                                    />
                                    <button @click="selectCheckBox(room)">
                                        {{ room.name }}
                                    </button>
                                </td>
                                <td
                                    v-for="(type, index) in types"
                                    :key="index"
                                    class="pl-2 sm:pl-4 py-1 text-center"
                                >
                                    <div
                                        v-if="
                                            room.isSelected && type.isSelected
                                        "
                                    >
                                        <div v-if="room[type.book_type]">
                                            <div
                                                v-if="
                                                    room[type.book_type][
                                                        type.name
                                                    ]
                                                "
                                            >
                                                <div
                                                    v-if="
                                                        Array.isArray(
                                                            room[
                                                                type.book_type
                                                            ][type.name]
                                                        )
                                                    "
                                                >
                                                    {{
                                                        room[type.book_type][
                                                            type.name
                                                        ][
                                                            room[
                                                                type.book_type
                                                            ][type.name]
                                                                .length - 1
                                                        ].availability
                                                    }}
                                                    >
                                                    <input
                                                        v-model="
                                                            room[
                                                                type.book_type
                                                            ][type.name][
                                                                room[
                                                                    type
                                                                        .book_type
                                                                ][type.name]
                                                                    .length - 1
                                                            ].new_availability
                                                        "
                                                        class="
                                                            px-1
                                                            bg-transparent
                                                            w-20
                                                            border-b
                                                            border-black
                                                            focus:outline-none
                                                        "
                                                    />
                                                </div>
                                                <div v-else>
                                                    {{
                                                        room[type.book_type][
                                                            type.name
                                                        ].availability
                                                    }}><input
                                                        v-model="
                                                            room[
                                                                type.book_type
                                                            ][type.name]
                                                                .new_availability
                                                        "
                                                        class="
                                                            px-1
                                                            bg-transparent
                                                            w-20
                                                            border-b
                                                            border-black
                                                            focus:outline-none
                                                        "
                                                    />
                                                </div>
                                            </div>
                                            <div v-else>N/A</div>
                                        </div>
                                        <div v-else>N/A</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        v-if="
                            types
                                .filter(
                                    (a) =>
                                        a.book_type === 'hour' ||
                                        a.book_type === 'hourly'
                                )
                                .some((e) => e.isSelected === true) &&
                            checkRoomSelection(roomType)
                        "
                    >
                        <div class="text-sm lg:text-base">
                            {{ $t("dashboard.flexible") }}
                            <span class="text-red-600">{{
                                $t("dashboard.change_warning")
                            }}</span>
                        </div>
                        <div class="my-2 flex items-center">
                            <Switch
                                :toggle="flexibleMode"
                                @onPress="flexibleMode = !flexibleMode"
                            />
                            <span class="ml-2">{{
                                flexibleMode ? "Multiple Days" : "Date Range"
                            }}</span>
                        </div>
                        <div
                            class="
                                grid grid-cols-2
                                gap-4
                                sm:gap-8
                                m-2
                                sm:mx-0 sm:my-2
                            "
                        >
                            <div v-if="!flexibleMode">
                                <div class="text-sm text-gray-500">
                                    {{ $t("dashboard.start_date") }}
                                </div>
                                <div
                                    class="
                                        flex
                                        items-center
                                        border-b
                                        w-4/5
                                        sm:w-1/2
                                    "
                                >
                                    {{ startDateHour.replaceAll("/", "-") }}
                                    <button
                                        class="ml-3"
                                        @click="startHCalendar = true"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="
                                                icon
                                                icon-tabler
                                                icon-tabler-calendar
                                            "
                                            width="28"
                                            height="28"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#000000"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <rect
                                                x="4"
                                                y="5"
                                                width="16"
                                                height="16"
                                                rx="2"
                                            />
                                            <line
                                                x1="16"
                                                y1="3"
                                                x2="16"
                                                y2="7"
                                            />
                                            <line x1="8" y1="3" x2="8" y2="7" />
                                            <line
                                                x1="4"
                                                y1="11"
                                                x2="20"
                                                y2="11"
                                            />
                                            <line
                                                x1="11"
                                                y1="15"
                                                x2="12"
                                                y2="15"
                                            />
                                            <line
                                                x1="12"
                                                y1="15"
                                                x2="12"
                                                y2="18"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <CalendarModal
                                    v-if="startHCalendar"
                                    :sel-date="startDateHour"
                                    @onSelect="selectStartDateH"
                                    @onCancel="startHCalendar = false"
                                />
                            </div>
                            <div v-if="!flexibleMode">
                                <div class="text-sm text-gray-500">
                                    {{ $t("dashboard.end_date") }}
                                </div>
                                <div
                                    class="
                                        flex
                                        items-center
                                        border-b
                                        w-4/5
                                        sm:w-1/2
                                    "
                                >
                                    {{ endDateHour.replaceAll("/", "-") }}
                                    <button
                                        class="ml-3"
                                        @click="endHCalendar = true"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="
                                                icon
                                                icon-tabler
                                                icon-tabler-calendar
                                            "
                                            width="28"
                                            height="28"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#000000"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <rect
                                                x="4"
                                                y="5"
                                                width="16"
                                                height="16"
                                                rx="2"
                                            />
                                            <line
                                                x1="16"
                                                y1="3"
                                                x2="16"
                                                y2="7"
                                            />
                                            <line x1="8" y1="3" x2="8" y2="7" />
                                            <line
                                                x1="4"
                                                y1="11"
                                                x2="20"
                                                y2="11"
                                            />
                                            <line
                                                x1="11"
                                                y1="15"
                                                x2="12"
                                                y2="15"
                                            />
                                            <line
                                                x1="12"
                                                y1="15"
                                                x2="12"
                                                y2="18"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <CalendarModal
                                    v-if="endHCalendar"
                                    :sel-date="endDateHour"
                                    :min-date="startDateHour"
                                    @onSelect="selectEndDateH"
                                    @onCancel="endHCalendar = false"
                                />
                            </div>
                            <div v-if="flexibleMode" class="col-span-2 p-2">
                                <div class="flex flex-wrap">
                                    <button
                                        @click="
                                            selectedDates.splice(
                                                selectedDates.indexOf(date),
                                                1
                                            )
                                        "
                                        class="
                                            bg-yellow-400
                                            rounded-full
                                            text-white
                                            mr-2
                                            px-1
                                            text-sm
                                            px-2
                                            py-1
                                            transition
                                            mb-1.5
                                            duration-200
                                            ease-linear
                                        "
                                        v-for="(date, index) in selectedDates"
                                        :key="index"
                                    >
                                        {{ date }}
                                    </button>
                                </div>
                                <div class="flex justify-center">
                                    <Calendar
                                        @onLoad="getHolidayDate"
                                        @onChange="getHolidayDate"
                                        :is-toggle="true"
                                        :cal-holiday="calHoliday"
                                        :selected-dates="selectedDates"
                                        @onSelect="selectMultiDays"
                                        class="w-full bg-gray-200 rounded-md"
                                    />
                                </div>
                            </div>
                            <div>
                                <div class="text-sm text-gray-500">
                                    {{ $t("dashboard.start_time") }}
                                </div>
                                <TimePicker
                                    :time="currentTime"
                                    @onChange="checkPassMidnight"
                                />
                                <input
                                    ref="start_time"
                                    class="
                                        w-4/5
                                        sm:w-1/2
                                        border-b
                                        hidden
                                        focus:outline-none
                                    "
                                    type="time"
                                    max="24:00"
                                    min="00:00"
                                    step="900"
                                    maxlength="5"
                                />
                            </div>
                            <div>
                                <div class="text-sm text-gray-500">
                                    {{ $t("dashboard.end_time") }}
                                </div>
                                <TimePicker
                                    :time="currentTime"
                                    @onChange="checkPassMidnightEnd"
                                />
                                <input
                                    ref="end_time"
                                    class="
                                        w-4/5
                                        sm:w-1/2
                                        border-b
                                        hidden
                                        focus:outline-none
                                    "
                                    type="time"
                                    max="24:00"
                                    min="00:00"
                                    step="900"
                                    maxlength="5"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="
                            types
                                .filter(
                                    (a) =>
                                        a.book_type === 'session' ||
                                        a.book_type === 'overnight' ||
                                        a.book_type === 'day' ||
                                        a.book_type === 'package'
                                )
                                .some((e) => e.isSelected === true) &&
                            checkRoomSelection(roomType)
                        "
                        class="text-sm sm:text-base mt-8 pt-4"
                        :class="{
                            'mt-4 pt-2 border-t border-gray-400':
                                types
                                    .filter(
                                        (a) =>
                                            a.book_type === 'hour' ||
                                            a.book_type === 'hourly'
                                    )
                                    .some((e) => e.isSelected === true) &&
                                checkRoomSelection(roomType)
                        }"
                    >
                        <span
                            v-if="
                                types
                                    .filter(
                                        (a) =>
                                            a.book_type === 'session' ||
                                            a.book_type === 'overnight' ||
                                            a.book_type === 'day' ||
                                            a.book_type === 'package'
                                    )
                                    .some((e) => e.isSelected === true) &&
                                checkRoomSelection(roomType)
                            "
                            >{{ $t("dashboard.session") }} /
                            {{ $t("dashboard.overnight") }}</span
                        >
                        <span
                            v-else-if="
                                types
                                    .filter((a) => a.book_type === 'session')
                                    .some((e) => e.isSelected === true) &&
                                checkRoomSelection(roomType)
                            "
                            >{{ $t("dashboard.session") }}</span
                        >
                        <span v-else>{{ $t("dashboard.overnight") }}</span>
                        <div class="my-2 flex items-center">
                            <Switch
                                :toggle="sessionMode"
                                @onPress="sessionMode = !sessionMode"
                            />
                            <span class="ml-2">{{
                                sessionMode ? "Multiple Days" : "Date Range"
                            }}</span>
                        </div>
                        <div
                            v-if="!sessionMode"
                            class="
                                grid grid-cols-2
                                gap-4
                                sm:gap-8
                                m-2
                                sm:mx-0 sm:my-2
                            "
                        >
                            <div>
                                <div class="text-sm text-gray-500">
                                    {{ $t("dashboard.start_date") }}
                                </div>
                                <div
                                    class="
                                        flex
                                        items-center
                                        border-b
                                        w-4/5
                                        sm:w-1/2
                                    "
                                >
                                    {{ startDate.replaceAll("/", "-") }}
                                    <button
                                        class="ml-3"
                                        @click="startCalendar = true"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="
                                                icon
                                                icon-tabler
                                                icon-tabler-calendar
                                            "
                                            width="28"
                                            height="28"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#000000"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <rect
                                                x="4"
                                                y="5"
                                                width="16"
                                                height="16"
                                                rx="2"
                                            />
                                            <line
                                                x1="16"
                                                y1="3"
                                                x2="16"
                                                y2="7"
                                            />
                                            <line x1="8" y1="3" x2="8" y2="7" />
                                            <line
                                                x1="4"
                                                y1="11"
                                                x2="20"
                                                y2="11"
                                            />
                                            <line
                                                x1="11"
                                                y1="15"
                                                x2="12"
                                                y2="15"
                                            />
                                            <line
                                                x1="12"
                                                y1="15"
                                                x2="12"
                                                y2="18"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <CalendarModal
                                    v-if="startCalendar"
                                    :sel-date="startDate"
                                    @onSelect="selectStartDate"
                                    @onCancel="startCalendar = false"
                                />
                            </div>
                            <div>
                                <div class="text-sm text-gray-500">
                                    {{ $t("dashboard.end_date") }}
                                </div>
                                <div
                                    class="
                                        flex
                                        items-center
                                        border-b
                                        w-4/5
                                        sm:w-1/2
                                    "
                                >
                                    {{ endDate.replaceAll("/", "-") }}
                                    <button
                                        class="ml-3"
                                        @click="endCalendar = true"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="
                                                icon
                                                icon-tabler
                                                icon-tabler-calendar
                                            "
                                            width="28"
                                            height="28"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#000000"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <rect
                                                x="4"
                                                y="5"
                                                width="16"
                                                height="16"
                                                rx="2"
                                            />
                                            <line
                                                x1="16"
                                                y1="3"
                                                x2="16"
                                                y2="7"
                                            />
                                            <line x1="8" y1="3" x2="8" y2="7" />
                                            <line
                                                x1="4"
                                                y1="11"
                                                x2="20"
                                                y2="11"
                                            />
                                            <line
                                                x1="11"
                                                y1="15"
                                                x2="12"
                                                y2="15"
                                            />
                                            <line
                                                x1="12"
                                                y1="15"
                                                x2="12"
                                                y2="18"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <CalendarModal
                                    v-if="endCalendar"
                                    :sel-date="endDate"
                                    :min-date="startDate"
                                    @onSelect="selectEndDate"
                                    @onCancel="endCalendar = false"
                                />
                            </div>
                        </div>
                        <div v-else class="col-span-2 p-2">
                            <div class="flex flex-wrap">
                                <button
                                    @click="
                                        selectedDatesSes.splice(
                                            selectedDatesSes.indexOf(date),
                                            1
                                        )
                                    "
                                    class="
                                        bg-yellow-400
                                        rounded-full
                                        text-white
                                        mr-2
                                        px-1
                                        text-sm
                                        px-2
                                        py-1
                                        transition
                                        mb-1.5
                                        duration-200
                                        ease-linear
                                    "
                                    v-for="(date, index) in selectedDatesSes"
                                    :key="index"
                                >
                                    {{ date }}
                                </button>
                            </div>
                            <div class="flex justify-center">
                                <Calendar
                                    @onLoad="getHolidayDate"
                                    @onChange="getHolidayDate"
                                    :is-toggle="true"
                                    :cal-holiday="calHoliday"
                                    :selected-dates="selectedDatesSes"
                                    @onSelect="selectMultiDaysSes"
                                    class="w-full bg-gray-200 rounded-md"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4 flex flex-1 justify-end">
                    <button
                        @click="updateAvailability"
                        :disabled="updateBtn"
                        class="px-5 py-2 cfn-btn"
                        :class="{
                            'filter grayscale': updateBtn
                        }"
                    >
                        {{ $t("dashboard.save") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Calendar from "@/components/Calendar"
import Switch from "@/components/Switch"
import CalendarModal from "@/components/CalendarModal"
import TimePicker from "@/components/TimePicker"

export default {
    name: "AvailabilityTable",
    components: { TimePicker, CalendarModal, Switch, Calendar },
    props: {
        date: { required: false, type: String },
        data: { required: true, type: Object },
        user: { required: true, type: Object },
        selectedSpace: { required: true, type: Number }
    },
    emits: ["onSuccess"],
    data() {
        return {
            types: [],
            flexible: [],
            sessions: [],
            overnight: [],
            selectAll: false,
            roomType: null,
            flexibleMode: false,
            sessionMode: false,
            startHCalendar: false,
            endHCalendar: false,
            startDateHour: "",
            endDateHour: "",
            startCalendar: false,
            endCalendar: false,
            startDate: "",
            endDate: "",
            today: "",
            currentTime: "",
            flexEndTime: 0,
            updateList: [],
            calHoliday: [],
            selectedDates: [],
            selectedDatesSes: [],
            updateBtn: false
        }
    },
    methods: {
        getType(obj) {
            for (let i = 0; i < Object.keys(obj).length; i++) {
                let sesType = Object.keys(obj[Object.keys(obj)[i]])
                for (let j = 0; j < sesType.length; j++) {
                    if (sesType[j] !== "name") {
                        let ses = obj[Object.keys(obj)[i]][sesType[j]]
                        for (let k = 0; k < Object.keys(ses).length; k++) {
                            let data
                            Array.isArray(ses[Object.keys(ses)[k]])
                                ? (data = ses[Object.keys(ses)[k]][0])
                                : (data = ses[Object.keys(ses)[k]])
                            if (
                                this.types.findIndex(
                                    (x) =>
                                        x.available_book_id ===
                                        data.available_book_id
                                ) === -1
                            ) {
                                this.types.push({
                                    book_type: data.book_type,
                                    name: Object.keys(ses)[k],
                                    available_book_id: data.available_book_id
                                })
                            }
                        }
                    }
                }
            }
            this.types.sort((a, b) => {
                const a1 = a.book_type
                const a2 = a.name
                const b1 = b.book_type
                const b2 = b.name
                if (a1 === "overnight" || b1 === "overnight") return -1
                if (a1 < b1) return -1
                if (a1 > b1) return 1
                if (a2 < b2) return -1
                if (a2 > b2) return 1
            })
        },
        selectAllSpace() {
            this.selectAll = !this.selectAll
            for (let i = 0; i < this.types.length; i++) {
                this.types[i].isSelected = this.selectAll
            }
            for (let i = 0; i < Object.keys(this.roomType).length; i++) {
                this.roomType[Object.keys(this.roomType)[i]].isSelected =
                    this.selectAll
            }
            this.checkSelection(this.roomType)
        },
        setZero(obj) {
            for (let i = 0; i < Object.keys(obj).length; i++) {
                let sesType = obj[Object.keys(obj)[i]]
                for (let j = 0; j < this.types.length; j++) {
                    if (
                        sesType[this.types[j].book_type] &&
                        sesType[this.types[j].book_type][this.types[j].name]
                    ) {
                        if (this.types[j].isSelected && sesType.isSelected) {
                            if (
                                Array.isArray(
                                    sesType[this.types[j].book_type][
                                        this.types[j].name
                                    ]
                                )
                            ) {
                                sesType[this.types[j].book_type][
                                    this.types[j].name
                                ][
                                    sesType[this.types[j].book_type][
                                        this.types[j].name
                                    ].length - 1
                                ].new_availability
                                    ? (sesType[this.types[j].book_type][
                                          this.types[j].name
                                      ][
                                          sesType[this.types[j].book_type][
                                              this.types[j].name
                                          ].length - 1
                                      ].new_availability = 0)
                                    : null
                            } else {
                                sesType[this.types[j].book_type][
                                    this.types[j].name
                                ].new_availability
                                    ? (sesType[this.types[j].book_type][
                                          this.types[j].name
                                      ].new_availability = 0)
                                    : null
                            }
                        } else {
                            sesType[this.types[j].book_type][
                                this.types[j].name
                            ].new_availability = null
                        }
                    }
                }
            }
        },
        checkSelection(obj) {
            for (let i = 0; i < Object.keys(obj).length; i++) {
                let sesType = obj[Object.keys(obj)[i]]
                for (let j = 0; j < this.types.length; j++) {
                    if (
                        sesType[this.types[j].book_type] &&
                        sesType[this.types[j].book_type][this.types[j].name]
                    ) {
                        if (this.types[j].isSelected && sesType.isSelected) {
                            if (
                                Array.isArray(
                                    sesType[this.types[j].book_type][
                                        this.types[j].name
                                    ]
                                )
                            ) {
                                !sesType[this.types[j].book_type][
                                    this.types[j].name
                                ][
                                    sesType[this.types[j].book_type][
                                        this.types[j].name
                                    ].length - 1
                                ].new_availability
                                    ? (sesType[this.types[j].book_type][
                                          this.types[j].name
                                      ][
                                          sesType[this.types[j].book_type][
                                              this.types[j].name
                                          ].length - 1
                                      ].new_availability = 0)
                                    : null
                            } else {
                                !sesType[this.types[j].book_type][
                                    this.types[j].name
                                ].new_availability
                                    ? (sesType[this.types[j].book_type][
                                          this.types[j].name
                                      ].new_availability = 0)
                                    : null
                            }
                        } else {
                            sesType[this.types[j].book_type][
                                this.types[j].name
                            ].new_availability = null
                        }
                    }
                }
            }
        },
        checkRoomSelection(obj) {
            for (let i = 0; i < Object.keys(obj).length; i++) {
                let sesType = obj[Object.keys(obj)[i]]
                if (sesType.isSelected) return true
            }
        },
        selectCheckBox(obj) {
            obj.isSelected = !obj.isSelected
            this.checkSelection(this.roomType)
        },
        formatDate() {
            let d = new Date()
            let year = d.getFullYear()
            let month = d.getMonth() + 1
            let date = d.getDate()
            let hour = d.getHours()
            let min = d.getMinutes()
            this.today = `${year}-${month >= 10 ? month : "0" + month}-${
                date >= 10 ? date : "0" + date
            }`
            if (this.date) {
                this.startDateHour =
                    this.endDateHour =
                    this.startDate =
                    this.endDate =
                        this.date.replaceAll("/", "-")
            } else
                this.startDateHour =
                    this.endDateHour =
                    this.startDate =
                    this.endDate =
                        this.today.replaceAll("/", "-")
            this.currentTime = `${hour}:${min >= 10 ? min : "0" + min}`
        },
        async getHolidayDate(val) {
            this.tempMonth = val
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/utils/getPublicHoliday?month=${val.month}&year=${
                val.year
            }&country_code=${
                this.user.space_info_list[this.selectedSpace].country_code
            }`
            const res = await this.$axios.get(url)
            this.calHoliday = res.data.data.public_holiday
        },
        selectStartDateH(val) {
            this.startDateHour = val
            this.startDateHour > this.endDateHour
                ? (this.endDateHour = val)
                : null
        },
        selectEndDateH(val) {
            this.endDateHour = val
        },
        checkPassMidnight(val) {
            this.$refs.start_time.value = val
            if (
                this.$refs.start_time.value &&
                this.$refs.end_time.value &&
                this.$refs.end_time.value < this.$refs.start_time.value
            ) {
                this.flexEndTime = 1
            } else this.flexEndTime = 0
        },
        checkPassMidnightEnd(val) {
            this.$refs.end_time.value = val
            if (
                this.$refs.start_time.value &&
                this.$refs.end_time.value &&
                this.$refs.end_time.value < this.$refs.start_time.value
            ) {
                this.flexEndTime = 1
            } else this.flexEndTime = 0
        },
        selectStartDate(val) {
            this.startDate > this.endDate ? (this.endDate = val) : null
        },
        selectEndDate(val) {
            this.endDate = val
        },
        async selectMultiDays(val) {
            let date = `${val.year}-${
                val.month > 9 ? val.month : "0" + val.month
            }-${val.date > 9 ? val.date : "0" + val.date}`
            if (this.selectedDates.includes(date)) {
                this.selectedDates.splice(this.selectedDates.indexOf(date), 1)
            } else this.selectedDates.push(date)
            this.selectedDates.sort()
        },
        async selectMultiDaysSes(val) {
            let date = `${val.year}-${
                val.month > 9 ? val.month : "0" + val.month
            }-${val.date > 9 ? val.date : "0" + val.date}`
            if (this.selectedDatesSes.includes(date)) {
                this.selectedDatesSes.splice(
                    this.selectedDatesSes.indexOf(date),
                    1
                )
            } else this.selectedDatesSes.push(date)
            this.selectedDatesSes.sort()
        },
        updateAvailability() {
            let list = []
            this.updateBtn = true
            if (this.validateChanges()) {
                if (
                    this.flexibleMode &&
                    this.$refs.start_time.value > this.$refs.end_time.value
                ) {
                    this.updateBtn = false
                    return alert("End time must be after start time")
                }
                for (let i = 0; i < Object.keys(this.roomType).length; i++) {
                    let sesType = this.roomType[Object.keys(this.roomType)[i]]
                    for (let j = 0; j < this.types.length; j++) {
                        if (
                            sesType[this.types[j].book_type] &&
                            sesType[this.types[j].book_type][this.types[j].name]
                        ) {
                            if (
                                this.types[j].isSelected &&
                                sesType.isSelected
                            ) {
                                let r
                                if (
                                    Array.isArray(
                                        sesType[this.types[j].book_type][
                                            this.types[j].name
                                        ]
                                    )
                                ) {
                                    r =
                                        sesType[this.types[j].book_type][
                                            this.types[j].name
                                        ][
                                            sesType[this.types[j].book_type][
                                                this.types[j].name
                                            ].length - 1
                                        ]
                                } else {
                                    r =
                                        sesType[this.types[j].book_type][
                                            this.types[j].name
                                        ]
                                }
                                let d = {
                                    space_id: r.space_id,
                                    space_type: r.space_type,
                                    available_book_id: r.available_book_id,
                                    start_time:
                                        r.book_type === "hour" ||
                                        r.book_type === "hourly"
                                            ? `${this.$refs.start_time.value}:00`
                                            : r.default_start_time,
                                    end_time:
                                        r.book_type === "hour" ||
                                        r.book_type === "hourly"
                                            ? `${this.$refs.end_time.value}:00`
                                            : r.default_start_time,
                                    availability: parseInt(r.new_availability),
                                    venue_type: r.venue_type,
                                    book_type: r.book_type,
                                    cross_day: r.cross_day
                                }
                                if (
                                    r.book_type === "hour" ||
                                    r.book_type === "hourly"
                                ) {
                                    this.flexibleMode
                                        ? (d.mode = 0)
                                        : (d.mode = 1)
                                    this.flexibleMode
                                        ? (d.start_date_list =
                                              this.selectedDates)
                                        : (d.start_date =
                                              this.startDateHour.replaceAll(
                                                  "/",
                                                  "-"
                                              )) &&
                                          (d.end_date =
                                              this.endDateHour.replaceAll(
                                                  "/",
                                                  "-"
                                              ))
                                } else {
                                    this.sessionMode
                                        ? (d.mode = 0)
                                        : (d.mode = 1)
                                    this.flexibleMode
                                        ? (d.start_date_list =
                                              this.selectedDatesSes)
                                        : (d.start_date =
                                              this.startDate.replaceAll(
                                                  "/",
                                                  "-"
                                              )) &&
                                          (d.end_date = this.endDate.replaceAll(
                                              "/",
                                              "-"
                                          ))
                                }
                                list.push(d)
                            }
                        }
                    }
                }
                const data = {
                    modify_list: list
                }
                if (data.modify_list.length > 0) {
                    this.updateBtn = false
                    this.updateAvailabilityReq(data)
                } else {
                    this.updateBtn = false
                    return alert("Please change something first.")
                }
            } else this.updateBtn = false
        },
        validateChanges() {
            if (this.types.some((e) => e.isSelected === true)) {
                if (
                    this.flexible.some((e) => e.isSelected === true) &&
                    (!this.startDateHour ||
                        !this.endDateHour ||
                        !this.$refs.start_time.value ||
                        !this.$refs.end_time.value)
                ) {
                    alert("Please select at least one day.")
                    return false
                }
                if (
                    this.types
                        .filter(
                            (a) =>
                                a.book_type === "hour" ||
                                a.book_type === "hourly"
                        )
                        .some((e) => e.isSelected === true) &&
                    !this.flexibleMode
                ) {
                    if (
                        new Date(
                            `${this.endDateHour.replaceAll("/", "-")}T${
                                this.$refs.end_time.value
                            }`
                        ) <=
                        new Date(
                            `${this.startDateHour.replaceAll("/", "-")}T${
                                this.$refs.start_time.value
                            }`
                        )
                    ) {
                        alert("End date must be after start date.")
                        return false
                    }
                }
                if (
                    this.types
                        .filter(
                            (a) =>
                                a.book_type === "session" ||
                                a.book_type === "overnight"
                        )
                        .some((e) => e.isSelected === true) &&
                    (!this.startDate || !this.endDate)
                ) {
                    alert("Please select at least one day.")
                    return false
                }
                return true
            } else {
                alert("Please select at least one book type.")
                return false
            }
        },
        updateAvailabilityReq(data) {
            const url = `${process.env.VUE_APP_API_BASE_URL}/home/updateRoomAvailability`
            this.$axios
                .put(url, data)
                .then((r) => {
                    if (r.data.Success) {
                        this.isHourChanged = false
                        this.isSessionChanged = false
                        this.isOvernightChanged = false
                        // this.getAvailability()
                        this.updateBtn = false
                        this.$emit("onSuccess")
                        alert("Success!")
                    } else {
                        this.updateBtn = false
                    }
                })
                .catch((error) => {
                    this.updateBtn = false
                    if (error.response) {
                        // Request made and server responded
                        error.response.data
                            ? alert(error.response.data)
                            : alert(error)
                    } else if (error.request) {
                        // The request was made but no response was received
                    } else {
                        // Something happened in setting up the request that triggered an Error
                    }
                })
        }
    },
    mounted() {
        this.formatDate()
        this.roomType = this.data
        this.getType(this.roomType)
    },
    watch: {
        data(to, from) {
            this.types = []
            this.getType(this.roomType)
        }
    }
}
</script>

<style scoped></style>
