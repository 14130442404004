<template>
    <div class="relative">
        <div v-if="type === 'xs'">
            <div
                class="
                    absolute
                    h-6
                    w-6
                    border-t-4 border-b-4 border-yellow-400
                    rounded-full
                    animate-spin
                "
            ></div>
            <div
                class="
                    h-6
                    w-6
                    border-r-2 border-l-2 border-gray-200
                    rounded-full
                    animate-spin
                "
            ></div>
        </div>
        <div v-if="type === 'sm'">
            <div
                class="
                    absolute
                    h-12
                    w-12
                    border-t-4 border-b-4 border-yellow-400
                    rounded-full
                    animate-spin
                "
            ></div>
            <div
                class="
                    h-12
                    w-12
                    border-r-2 border-l-2 border-gray-200
                    rounded-full
                    animate-spin
                "
            ></div>
        </div>
        <div v-if="type === 'md'">
            <div
                class="
                    absolute
                    h-16
                    w-16
                    border-t-4 border-b-4 border-yellow-400
                    rounded-full
                    animate-spin
                "
            ></div>
            <div
                class="
                    h-16
                    w-16
                    border-r-2 border-l-2 border-gray-200
                    rounded-full
                    animate-spin
                "
            ></div>
        </div>
        <div v-if="type === 'lg'">
            <div
                class="
                    absolute
                    h-32
                    w-32
                    border-t-4 border-b-4 border-yellow-400
                    rounded-full
                    animate-spin
                "
            ></div>
            <div
                class="
                    h-32
                    w-32
                    border-r-2 border-l-2 border-gray-200
                    rounded-full
                    animate-spin
                "
            ></div>
        </div>
        <div v-if="type === 'custom'">
            <div
                class="
                    absolute
                    border-t-4 border-b-4 border-yellow-400
                    rounded-full
                    animate-spin
                "
                :class="[height, width]"
            ></div>
            <div
                class="
                    border-r-2 border-l-2 border-gray-200
                    rounded-full
                    animate-spin
                "
                :class="[height, width]"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Loader",
    props: {
        type: String,
        height: String,
        width: String
    }
}
</script>

<style scoped></style>
