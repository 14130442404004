<template>
    <div
        class="
            mt-2
            p-2
            bg-white
            w-full
            max-w-min
            rounded-lg
            shadow-md
            border border-gray-300
        "
    >
        <div class="flex">
            <select
                @change="handleInput"
                name="hours"
                ref="hours"
                class="bg-transparent appearance-none outline-none"
            >
                <option
                    :selected="
                        parseInt(content.split(':')[0]) === 0
                            ? i === 12
                            : parseInt(content.split(':')[0]) > 12
                            ? i === parseInt(content.split(':')[0]) - 12
                            : i === parseInt(content.split(':')[0])
                    "
                    v-for="(i, index) in 12"
                    :key="index"
                    :value="i"
                >
                    {{ i }}
                </option>
            </select>
            <span class="mr-3">:</span>
            <select
                @change="handleInput"
                name="minutes"
                ref="mins"
                class="bg-transparent appearance-none outline-none mr-4"
            >
                <option
                    :selected="
                        parseInt(content.split(':')[1]) >= 0 &&
                        parseInt(content.split(':')[1]) < 15
                    "
                    value="00"
                >
                    00
                </option>
                <option
                    :selected="
                        parseInt(content.split(':')[1]) >= 15 &&
                        parseInt(content.split(':')[1]) < 30
                    "
                    value="15"
                >
                    15
                </option>
                <option
                    :selected="
                        parseInt(content.split(':')[1]) >= 30 &&
                        parseInt(content.split(':')[1]) < 45
                    "
                    value="30"
                >
                    30
                </option>
                <option
                    :selected="
                        parseInt(content.split(':')[1]) >= 45 &&
                        parseInt(content.split(':')[1]) < 60
                    "
                    value="45"
                >
                    45
                </option>
            </select>
            <select
                @change="handleInput"
                name="ampm"
                ref="ampm"
                class="bg-transparent appearance-none outline-none"
            >
                <option value="am">AM</option>
                <option
                    :selected="
                        parseInt(content.split(':')[0]) > 11 &&
                        parseInt(content.split(':')[0]) !== 24 &&
                        parseInt(content.split(':')[0]) !== 0
                    "
                    value="pm"
                >
                    PM
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: "TimePicker",
    props: {
        time: String
    },
    emits: ["onChange"],
    data() {
        return {
            content: this.time,
            date: new Date()
        }
    },
    methods: {
        handleInput() {
            let h = this.$refs.hours.value
            let m = this.$refs.mins.value
            if (this.$refs.ampm.value === "pm" && h !== "12") {
                h = (parseInt(h) + 12).toString()
            }
            if (this.$refs.ampm.value === "am" && h === "12") {
                h = "0"
            }
            let time = `${parseInt(h) < 10 ? "0" + h : h}:${m}`
            this.$emit("onChange", time)
        }
    },
    mounted() {
        let h = this.$refs.hours.value
        let m = this.$refs.mins.value
        if (this.$refs.ampm.value === "pm" && h !== "12") {
            h = (parseInt(h) + 12).toString()
        }
        if (this.$refs.ampm.value === "am" && h === "12") {
            h = "0"
        }
        let time = `${parseInt(h) < 10 ? "0" + h : h}:${m}`
        this.$emit("onChange", time)
    }
}
</script>

<style scoped></style>
